import CardSectionCounter from './utils/CardSectionCounter';

const App = {
  cardSectionCounter: new CardSectionCounter(),

  async init() {
    this.cardSectionCounter.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
